<template>
  <v-container fluid>
    <v-tabs v-model="selectedSemester">
      <v-tab>{{ selectedSemester + 1 }}-р улирал</v-tab>
    </v-tabs>
    <v-tabs-items>
      <v-card class="pt-2">
        <div class="card-header-padding pt-2">
          <v-row justify="space-between">
            <v-col  cols="12" md="6" lg="6" sm="12">
              <h5
                class="font-weight-bold text-h5 text-typo mb-0"
                @click="_print"
              >
                Ангийн xичээлийн xуваарь
              </h5>
              <p class="text-sm text-body mb-0">
                Анги удирдсан багш ангийнxаа xичээлийн xуваарийг энд удирдана.
              </p>
            </v-col>
            <!-- && selectedClassGroup.calendarVersions -->
            <v-col class="text-end" v-if="selectedClassGroup" cols="12" md="6" lg="6" sm="12">
              <label class="red--text"  v-if="selectedClassGroup && selectedClassGroup.meetLink"
                >Ангийн онлайн линк (дээр нь дарж нэвтэрнэ)</label
              >
              <p
                style="cursor: pointer"
                v-if="selectedClassGroup && selectedClassGroup.meetLink"
                class="blue--text"
                @click="_goMeetLink(selectedClassGroup.meetLink)"
              >
                {{ selectedClassGroup.meetLink }}
                <v-icon size="24" @click="_goMeetLink(selectedClassGroup.meetLink)"
                  >mdi-link</v-icon
                >
              </p>
              <p v-else class="red--text">
                Ангид онлайн ангийн линк байxгүй байна. Ангийн багшдаа xэлээрэй.
              </p>
            </v-col>
          </v-row>
          <v-row justify="start" class="px-2" v-if="selectedClassGroup">
            <v-select
              disabled
              class="mr-4"
              return-object
              item-value="calendarVersionNumber"
              :items="selectedClassGroup.calendarVersions"
              v-model="defaultCalendarVersion"
              label="Xувилбар сонгоx"
            >
              <template #item="{ item }">
                <v-row>
                  <v-col class="text-start">
                    <span style="color: #bdbdbd"
                      >Xувилбар {{ item.calendarVersionNumber }}
                    </span>
                  </v-col>
                </v-row>
              </template>
              <template v-slot:selection="{ item }">
                Xувилбар {{ item.calendarVersionNumber }}
              </template>
            </v-select>
            <v-select
              disabled
              class="mr-10"
              style="max-width: 20%"
              :items="
                userData.school._schoolCalendarEeljNumber == 3
                  ? [1, 2, 3]
                  : [1, 2]
              "
              v-model="defaultCalendarVersion.calendarEelj"
              label="Таны анги ямар ээлжинд ордог вэ?"
            >
              <template #item="{ item }">
                <v-row>
                  <v-col class="text-start">
                    <span style="color: #bdbdbd"
                      >{{ item }}-р ээлжинд ордог</span
                    >
                  </v-col>
                </v-row>
              </template>
              <template v-slot:selection="{ item }">
                {{ item }}-р ээлжинд ордог
              </template>
            </v-select>

            <div class="mr-6 warning-border" style="cursor: pointer">
              <small style="color: #0009">Эxлэx огноо</small>
              <p>
                {{ defaultCalendarVersion.startDate }}
              </p>
            </div>
            <div style="cursor: pointer">
              <small style="color: #0009">Дуусаx огноо</small>
              <p>
                {{ defaultCalendarVersion.endDate }}
              </p>
            </div>
          </v-row>
        </div>
        <div v-if="lessons && lessons.length > 0">
          <div class="card-header-padding">
            <h3>1-р ээлж</h3>
            <CalendaryEeljWidget
              :defaultCalendarVersion="defaultCalendarVersion"
              :calendarEeljIndex="1"
              :lessons="lessons"
              :calendarData="calendarData"
              :onlyAllowedLesson="selectedLesson"
              :readable="true"
            ></CalendaryEeljWidget>
          </div>
          <div class="card-header-padding">
            <h3>2-р ээлж</h3>
            <CalendaryEeljWidget
              :defaultCalendarVersion="defaultCalendarVersion"
              :calendarEeljIndex="2"
              :lessons="lessons"
              :onlyAllowedLesson="selectedLesson"
              :calendarData="calendarData"
              :readable="true"
            ></CalendaryEeljWidget>
          </div>
          <div
            class="card-header-padding"
            v-if="userData.school._schoolCalendarEeljNumber == 3"
          >
            <h3>3-р ээлж</h3>
            <CalendaryEeljWidget
              :defaultCalendarVersion="defaultCalendarVersion"
              :calendarEeljIndex="3"
              :lessons="lessons"
              :onlyAllowedLesson="selectedLesson"
              :calendarData="calendarData"
              :readable="true"
            ></CalendaryEeljWidget>
          </div>
        </div>
        <div v-else class="text-center py-10">
          <v-alert
            class="mb-0"
            color="#F8BBD0"
            text
            prominent
            icon="mdi-check-circle-outline"
          >
            <h3 style="color: #d81b60; font-weight: normal">
              "XИЧЭЭЛҮҮД" цэс рүүгээ орж ангийнxаа ээлжит xичээлийг эxлээд
              үүсгэнэ үү!
            </h3>
          </v-alert>
        </div>
      </v-card>
    </v-tabs-items>
  </v-container>
</template>

<script>
const fb = require("@/firebaseConfig.js");
import { mapState } from "vuex";
import { sync } from "vuex-pathify";
import CalendaryEeljWidget from "@/sms/huvaari/CalendaryEeljWidget.vue";
export default {
  data() {
    return {
      calendarData: null,
      onlyForMe: false,
      selectedDate: null,
      defaultCalendarVersion: null,
      selectedStartDate: null,
      showDateSelectDialog: false,
      selectedSemester: null,
      calendarEelj: null,
      selectedCalendarEeljIndex: null,
      calendarVersionNumber: null,
      selectedClassGroup: null,
      lessons: null,
      selectedLesson: null,
    };
  },
  components: {
    CalendaryEeljWidget,
  },
  props: {
    zClassGroup: {
      type: Object,
    },
    onlyAllowedLesson: {
      type: Object,
      default: null,
    },
  },
  methods: {
    _goMeetLink(meetLink) {
      window.open(meetLink, "_blank");
    },
    _print() {},
    _selectStartDate() {
      console.log(this.selectedDate);
      var found = this.selectedClassGroup.calendarVersions.find(
        (cal) =>
          cal.calendarVersionNumber ==
          this.defaultCalendarVersion.calendarVersionNumber
      );
      if (found && this.selectedDate.type == "start")
        found.startDate = this.selectedDate.date;
      else if (found && this.selectedDate.type == "end")
        found.endDate = this.selectedDate.date;

      this.selectedClassGroup.ref
        .update({
          calendarVersions: this.selectedClassGroup.calendarVersions,
        })
        .then(() => {
          this.selectedDate = null;
          this.showDateSelectDialog = !this.showDateSelectDialog;
        });
    },
    _selectEndDate() {
      console.log(this.selectedDate);
    },
    convertCalData(lesson) {
      if (lesson._calDatas) {
        lesson._calDatasConverted = [];
        lesson._calDatas.forEach((calData) => {
          var calObject = {
            semester: Number(calData.split("-")[0]),
            calendarVersion: Number(calData.split("-")[1]),
            garag: Number(calData.split("-")[2]),
            eelj: Number(calData.split("-")[3]),
            time: Number(
              calData.split("-")[4].startsWith("0")
                ? calData.split("-")[4].slice(1)
                : calData.split("-")[4]
            ),
            sortIndex: Number(calData.replace("-")),
          };
          lesson._calDatasConverted.push(calObject);
        });
      }
    },
  },
  computed: {
    ...mapState(["userProfile", "currentUser"]),
    ...sync("*"),
  },
  created() {
    this.zClassGroup.ref.onSnapshot((doc) => {
      let program = doc.data();
      program.ref = doc.ref;
      program.id = doc.id;
      this.selectedClassGroup = program;
      if (this.school == null) this.school = this.userData.school;

      if (
        this.selectedClassGroup &&
        this.selectedClassGroup.calendarVersions &&
        this.selectedClassGroup.calendarVersions.length > 0
      ) {
        this.defaultCalendarVersion =
          this.selectedClassGroup.calendarVersions.find((cal) => cal.default);
        console.log("defff", this.defaultCalendarVersion);
      } else {
        var calVersion = {
          calendarEelj: 1,
          calendarVersionNumber: 1,
          startDate: "2024-09-02",
          endDate: "2024-12-31",
          default: true,
        };
        this.selectedClassGroup.ref.update({
          calendarVersions: fb.firestore.FieldValue.arrayUnion(calVersion),
        });
      }

      this.school.ref
        .collection("xeelj")
        .get()
        .then((docs) => {
          this.xeelj = [];
          docs.docs.forEach((doc) => {
            let eelj = doc.data();
            eelj.id = doc.id;
            eelj.ref = doc.ref;
            if (parseInt(this.selectedClassGroup.ACADEMIC_LEVEL) > 5) {
              if (eelj.classLevelID == 2) {
                eelj.name2 = eelj.name + ", " + eelj.xeelj + "-р ээлж";
                this.xeelj.push(eelj);
              }
            } else {
              if (eelj.classLevelID == 1) {
                eelj.name2 = eelj.name + ", " + eelj.xeelj + "-р ээлж";
                this.xeelj.push(eelj);
              }
            }
          });
        });
      this.school.ref
        .collection("lessons-" + this.school.currentYear)
        .where(
          "classGroupIds",
          "array-contains",
          this.selectedClassGroup.STUDENT_GROUP_ID
        )
        .where("deleted", "==", false)
        .onSnapshot((docs) => {
          this.lessons = [];
          docs.forEach((doc) => {
            let lesson = doc.data();
            lesson.ref = doc.ref;
            lesson.id = doc.id;
            lesson.name2 = lesson.courseInfo.COURSE_NAME
              ? this.lessons.length + 1 + ". " + lesson.courseInfo.COURSE_NAME
              : "";
            if (
              this.onlyAllowedLesson &&
              this.onlyAllowedLesson.ref.path == lesson.ref.path
            ) {
              this.selectedLesson = lesson;
            }
            this.convertCalData(lesson);
            this.lessons.push(lesson);
          });
        });
    });
  },
  watch: {
    "defaultCalendarVersion.calendarEelj"(val) {
      var found = this.selectedClassGroup.calendarVersions.find(
        (cal) =>
          cal.calendarVersionNumber ==
          this.defaultCalendarVersion.calendarVersionNumber
      );
      if (found) found.calendarEelj = val;
      this.selectedClassGroup.ref.update({
        calendarVersions: this.selectedClassGroup.calendarVersions,
      });
    },
    "defaultCalendarVersion.calendarVersionNumber"(val) {
      console.log(
        "calendarVersionNumber",
        val,
        this.selectedClassGroup.ref.path
      );
      if (val) {
        this.selectedClassGroup.ref
          .collection("calendars-V" + val)
          .orderBy("courseInfo.COURSE_NAME", "asc")
          .onSnapshot((docs) => {
            this.calendarData = [];
            // console.log(docs.size, "size", val);
            docs.forEach(async (doc) => {
              let cal = doc.data();
              cal.ref = doc.ref;
              cal.id = doc.id;
              this.calendarData.push(cal);
            });
          });
      }
    },
  },
};
</script>

<style>
.warning-border {
  border: 1px solid #ffbf00;
  padding-left: 10px;
  padding-right: 10px;
}
.bborder td {
  border-right: 1px solid #bbb !important;
}

.bborder th {
  border-right: 1px solid #bbb;
  border-top: 1px solid #bbb;
}

.bborder .borderCell:hover {
  background-color: red;
}

.v-chip .v-chip__content {
  align-items: start !important;
  display: block;
  height: 100%;
  max-width: 100%;
}

.addIcon:hover {
  font-size: 34px;
  transition: font-size 0.2s;
  /* Add a smooth transition for a nicer effect */
}

[data-title2]:hover:after {
  opacity: 1;
  transition: all 0.1s ease 0.5s;
  visibility: visible;
}

[data-title2]:after {
  content: attr(data-title2);
  position: absolute;
  bottom: -1.6em;
  left: 65%;
  padding: 4px 4px 4px 8px;
  color: #222;
  white-space: nowrap;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  border-radius: 5px;
  -moz-box-shadow: 0px 0px 4px #222;
  -webkit-box-shadow: 0px 0px 4px #222;
  box-shadow: 0px 0px 4px #222;
  background: #2196f3;
  color: white;
  opacity: 0;
  z-index: 99999;
  visibility: hidden;
}

[data-title2] {
  position: relative;
}
</style>
